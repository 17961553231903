import Head from "next/head";

const MengajarHomeSEO: React.FC = ({}) => {
  const collectionPageSchema = {
    "@context": "https://schema.org",
    "@type": ["CollectionPage"],
    name: "Mengajar Bersama CoLearn",
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/blog/mengajar`
  };

  const orgSchema = {
    "@context": "https://schema.org",
    "@type": "EducationalOrganization",
    name: "CoLearn",
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/blog/mengajar`,
    logo: `${process.env.NEXT_PUBLIC_SITE_URL}/assets/colearn_logo.png`,
    sameAs: [
      "https://www.facebook.com/colearn.id",
      "https://www.instagram.com/colearn.id/",
      "https://www.youtube.com/c/colearn",
      "https://www.linkedin.com/company/colearnid/",
      "https://twitter.com/colearn_id",
      "https://www.tiktok.com/@colearn.id"
    ]
  };

  return (
    <>
      <Head>
        <title>Mengajar Bersama CoLearn</title>
        <meta name="title" content="Mengajar Bersama CoLearn" />
        <meta
          name="description"
          content="Temukan informasi lengkap tentang dunia pendidikan dan tips menjadi pengajar kelas dunia. Wujudkan generasi siap untuk masa depan bersama Guru Juara CoLearn"
        />
        <meta property="og:title" content="Mengajar Bersama CoLearn" />
        <meta
          property="og:description"
          content="Temukan informasi lengkap tentang dunia pendidikan dan tips menjadi pengajar kelas dunia. Wujudkan generasi siap untuk masa depan bersama Guru Juara CoLearn"
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/assets/mengajar/mengajar-og.jpg`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@CoLearn_ID" />

        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_SITE_URL}/blog/mengajar`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(collectionPageSchema)
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(orgSchema)
          }}
        />
      </Head>
    </>
  );
};

export { MengajarHomeSEO };

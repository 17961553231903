import styled from "styled-components";
import { breakpoints } from "styles";

export const MengajarHeroContainer = styled.section`
  position: relative;
  min-height: 300px;
  background: url("/assets/mengajar/mengajar-home-background.png") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
  @media ${breakpoints.tablet} {
    min-height: 450px;
  }
`;

export const MengajarHeroTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  font-weight: bold;
  line-height: 1.25;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    margin: 0 0 24px 0;
  }
`;

export const MengajarHeroDesc = styled.p`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  font-weight: bold;
  line-height: 1.25;
  margin: 0 0 40px 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
    margin: 0 0 60px 0;
  }
`;

export const MengajarPostsGrid = styled.ul`
  padding: 0;
  margin: 32px 0;
  list-style: none;
  display: grid;
  gap: 48px;

  @media ${breakpoints.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const MengajarPostItem = styled.li``;

export const MengajarPostsNavigation = styled.nav`
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
`;

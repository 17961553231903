import { formatDistanceToNow, getTime } from "date-fns";
import { id as IdLocale } from "date-fns/locale";
import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import {
  PostCardContentWrapper,
  PostCardImageWrapper,
  PostCardMeta,
  PostCardTagLink,
  PostCardTitle,
  PostCardWrapper
} from "./post-card.styles";
import { IPostCard } from "./post-card.types";

const PostCard: React.FC<IPostCard> = ({ post }) => {
  const { feature_image, title, published_at, id, slug, primary_tag } = post;
  const date =
    published_at &&
    formatDistanceToNow(getTime(Date.parse(published_at)), {
      locale: IdLocale
    });

  return (
    <Link href={`/blog/mengajar/${primary_tag?.slug}/${slug}`}>
      <PostCardWrapper key={id}>
        <>
          <PostCardImageWrapper>
            {feature_image && (
              <Image
                alt={title || ""}
                src={feature_image}
                layout="fill"
                objectFit="cover"
              />
            )}
          </PostCardImageWrapper>

          <PostCardContentWrapper>
            <PostCardTitle>{title}</PostCardTitle>
            <PostCardMeta>
              {primary_tag && (
                <Link href={`/blog/mengajar/tag/${primary_tag.slug}`}>
                  <PostCardTagLink>{primary_tag.name}</PostCardTagLink>
                </Link>
              )}
              <time>{date} yang lalu</time>
            </PostCardMeta>
          </PostCardContentWrapper>
        </>
      </PostCardWrapper>
    </Link>
  );
};

export { PostCard };

import React from "react";
import { IIcon } from "./icons.types";

const Home: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = "currentColor"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12.3508V12.1889C22.9453 11.7003 22.7461 11.2832 22.4023 10.9377C21.5661 10.0976 20.7291 9.26069 19.8912 8.42682C19.8742 8.4099 19.8646 8.38686 19.8646 8.36266C19.8677 6.64236 19.8677 4.93492 19.8646 3.24033C19.864 2.96833 19.7897 2.73226 19.6416 2.53211C19.3852 2.18576 19.0264 2.0095 18.5652 2.00335C18.1389 1.99776 17.787 1.99916 17.5095 2.00755C17.1815 2.01761 16.8961 2.13432 16.6535 2.35767C16.4046 2.5869 16.2778 2.88238 16.2732 3.2441C16.2672 3.72408 16.2668 4.22753 16.272 4.75447C16.2722 4.78494 16.2614 4.78941 16.2393 4.76789C15.7317 4.26835 15.2232 3.76307 14.7139 3.25207C14.4724 3.00999 14.2705 2.82828 14.108 2.70696C13.3939 2.17443 12.4795 1.91488 11.5879 2.03061C10.793 2.13348 10.1313 2.44741 9.60277 2.97239C6.89488 5.66159 4.22294 8.31682 1.58695 10.9381C1.25065 11.2724 1.055 11.6852 1 12.1763V12.3231C1.03581 12.9221 1.29749 13.3876 1.78504 13.7194C2.07293 13.9151 2.40837 14.0153 2.79137 14.02C3.20473 14.0248 3.64473 14.0255 4.11137 14.0221C4.11581 14.0221 4.12007 14.0239 4.12322 14.0269C4.12636 14.03 4.12813 14.0342 4.12813 14.0385C4.12727 16.0341 4.12755 18.0253 4.12898 20.012C4.12898 20.2796 4.17911 20.538 4.27938 20.7873C4.5127 21.3673 5.04078 21.8008 5.66082 21.9405C5.83871 21.9804 6.11629 22.0003 6.49355 22C10.2118 21.998 13.9933 21.9969 17.8382 21.9966C18.2492 21.9964 18.6139 21.8977 18.9321 21.7006C19.3932 21.4155 19.7138 20.9689 19.822 20.4477C19.8535 20.2951 19.8692 20.0737 19.8689 19.7835C19.8666 17.8432 19.8656 15.9295 19.8659 14.0423C19.8659 14.0286 19.8729 14.0219 19.8869 14.0221C20.3074 14.0258 20.7334 14.0255 21.1648 14.0213C21.7704 14.0157 22.2521 13.7989 22.6098 13.3709C22.8476 13.0864 22.9777 12.7463 23 12.3508ZM12 18C13.6569 18 15 16.6569 15 15C15 13.3431 13.6569 12 12 12C10.3431 12 9 13.3431 9 15C9 16.6569 10.3431 18 12 18Z"
        fill={color}
      />
    </svg>
  );
};

export default Home;

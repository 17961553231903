import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const MengajarNavigationWrapper = styled.nav`
  background-color: ${({ theme }) => theme.colors.neutral[50]};
  padding: 8px 0;
  overflow-x: scroll;

  @media ${breakpoints.laptop} {
    overflow-x: initial;
  }
`;

export const MengajarNavigationMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const MengajarNavigationMenuItem = styled.li`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  margin-right: 16px;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.brand[500]};
  }

  @media ${breakpoints.laptop} {
    margin-right: 24px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const MengajarNavigationMenuLink = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  padding: 14px 0;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
`;

export const MengajarNavigationContainer = styled(Container)`
  display: flex;

  @media ${breakpoints.mobile} {
    padding: 0 12px;
  }

  @media ${breakpoints.tablet} {
    justify-content: space-between;
  }
`;

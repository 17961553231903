import styled from "styled-components";

export const PostCardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  transition: 0.25s all ease;
  cursor: pointer;
  overflow: hidden;
  height: 100%;

  &:hover {
    box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const PostCardImageWrapper = styled.figure`
  margin: 0;
  position: relative;
  aspect-ratio: 16/9;
`;

export const PostCardTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  line-height: 1.5;
`;

export const PostCardContentWrapper = styled.div`
  padding: 8px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const PostCardMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PostCardTagLink = styled.a`
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["sm"]};
  position: relative;
  z-index: 1;
`;

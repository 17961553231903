import React from "react";
import {
  MengajarNavigationWrapper,
  MengajarNavigationContainer,
  MengajarNavigationMenu,
  MengajarNavigationMenuItem,
  MengajarNavigationMenuLink
} from "./mengajar-navigation.styles";
import { IMengajarNavigation } from "./mengajar-navigation.types";
import Home from "components/icons/home";

const MengajarNavigation: React.FC<IMengajarNavigation> = ({ navigation }) => {
  return (
    <>
      {navigation && navigation.primary && (
        <MengajarNavigationWrapper>
          <MengajarNavigationContainer>
            <MengajarNavigationMenu>
              <MengajarNavigationMenuItem>
                <MengajarNavigationMenuLink href={`/blog/mengajar`}>
                  <Home height={20} width={20} />
                </MengajarNavigationMenuLink>
              </MengajarNavigationMenuItem>
              {navigation.primary.map((item, idx) => (
                <MengajarNavigationMenuItem key={`${item.label}-${idx}`}>
                  <MengajarNavigationMenuLink
                    href={`/blog/mengajar${item.url}`}>
                    {item.label}
                  </MengajarNavigationMenuLink>
                </MengajarNavigationMenuItem>
              ))}
            </MengajarNavigationMenu>
            {navigation.secondary && (
              <MengajarNavigationMenu>
                {navigation.secondary.map((item, idx) => (
                  <MengajarNavigationMenuItem key={`${item.label}-${idx}`}>
                    <MengajarNavigationMenuLink
                      href={`/blog/mengajar${item.url}`}>
                      {item.label}
                    </MengajarNavigationMenuLink>
                  </MengajarNavigationMenuItem>
                ))}
              </MengajarNavigationMenu>
            )}
          </MengajarNavigationContainer>
        </MengajarNavigationWrapper>
      )}
    </>
  );
};

export { MengajarNavigation };
